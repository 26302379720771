// src/App.js
import React from 'react';
import ComingSoon from './ComingSoon';

function App() {
  return (
    <div>
      <ComingSoon />
    </div>
  );
}

export default App;
