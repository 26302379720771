import React, { useState, useEffect } from 'react';
import './ComingSoon.css';
import lottie from 'lottie-web';
import Deals360Logo from './Deals360Logo.png';

const ComingSoon = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Initialize the Lottie animation as the background
    lottie.loadAnimation({
      container: document.getElementById('lottie-background'), // the dom element where the animation will be rendered
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'https://lottie.host/embed/8f94723a-3801-443a-a524-113abdb57d84/5vLVVBS6S4.json', // the Lottie animation JSON URL
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbxclo1OTl61JgmohJvE_OAyinYPfFCGZ0QGIrtuY_pmbi0Y5gCYB4Jdt1RMJ2WnpEc7/exec', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ email }),
      });

      const data = await response.json();
      setLoading(false);
      if (data.result === 'Success') {
        setMessage('Thanks for signing up! We will notify you soon.');
        setEmail('');
      } else {
        setMessage('Oops! Something went wrong. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      setMessage('Oops! Something went wrong. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <div className="coming-soon-container">
      <div id="lottie-background" className="lottie-background"></div> {/* Lottie animation background */}
      <div className="content">
        <img src="https://res.cloudinary.com/deals360/image/upload/f_auto,q_auto/v1/logos/no_bg/csktxbpnougaubjfixyj" alt="logo" className='overlay-image'></img>
        <h1>Coming Soon</h1>
        <p>We are working hard on our website.</p>
        <p>Stay tuned for updates!</p>
        <form className="email-signup" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />  
          <button type="submit" disabled={loading}>
            {loading ? 'Submitting...' : 'Notify Me'}
          </button>
        </form>
        {loading && <div className="loader"></div>}
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
};

export default ComingSoon;
